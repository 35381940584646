.footer {
  margin: 50px 0 0 0;
  padding: 25px 0;
  color: #ccc;
  background-color: #efefef;
  overflow: hidden;

  #sbi_load {
    display: none;
  }
  #sb_instagram {
    padding-right: 25px;
  }
  h2 {
    font-size: 20px;
    color: #5c6064;
    font-weight: bold;
    margin-bottom: 5px;
  }
  a {
    color: #999;
  }
  a:hover{
    text-decoration: underline;
  }
  ul {
    margin-left: 0;
  }
  ul li {
    font-size: 0.9rem;
    list-style-type: none;
    margin: 0 0 10px;
  }
}
