/*
Table of Contents:
- Button
- Card
*/

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
}

hr.divider {
  margin: 50px 0;
}

.orange {
  color: #f98e2d;
  fill: #f98e2d;
}

img.alignright {
  float: right;
  padding-left: 25px;
}
img.avatar {
  float: right;
  padding-left: 25px;
}

.header {
  border-bottom: 1px solid #efefef;
}
.header,
.top-bar,
.dropdown.menu {
  background-color: #fafafa;
  background-color: #fff;
}

.header li {
  margin-bottom: 0px;
}

.header .menu li a.button {
  color: #fff;
}
.header .menu li a.button.hollow {
  color: #f98e2d;
}
input#submit {
  padding: 10px;
}
label {
  display: block;
}

.post-details {
  margin: -10px 0 30px;
}
.post-details .date {
  color: #333;
  padding-right: 10px;
  border-right-width: 1px;
  border-right-color: #333;
  border-right-style: solid;
}
.post-details .author {
  padding-left: 10px;
}
.post-details .author a {
  color: #333;
}

.post-details .author a:hover {
  color: #000;
  text-decoration: underline;
}

.footer ul li a {
  text-decoration: none;
  font-size: 1rem;
}
.footer ul li {
  margin-bottom: 9px;
}
.footer h2 {
  margin: 0 50px 10px 0;
  border-bottom-width: 1px;
  border-bottom-color: #ccc;
  border-bottom-style: solid;
}

/* Small only */
@media screen and (max-width: 39.9375em) {}

/* Medium and up */
@media screen and (min-width: 40em) {}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {}






p {
  font-size: 0.9rem;
  color: #888;
}

hr {
  width: 100%;
}

/* Buttons */
a.button {
  text-decoration: none;
}


/* Card */
.card {
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.10);
}
.card-padded {
  padding: 10px;
}
.card h1 {
  border-bottom-color: #ccc;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.card .button {
  max-width: 250px;
}
.card .button.expanded {
  max-width: none;
}

#instafeed img {
  width: 23%;
  margin: 0 2% 2% 0;
}

.hamburger {
  text-transform: uppercase;
  display: none;
  float: right;
}

@media screen and (max-width: 39.9375em) {
  .responsiveNav {
    display: none;
  }
  .hamburger {
  text-transform: uppercase;
  display: block;
  }
}