/* Top Bar */
.header {
  .top-bar {
    position: fixed;
    padding: 11px 1em 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.10);
    z-index: 10;
  }

  .menu li {
    margin-left: 10px;
  }

  .menu li a {
    color: #414042;
    font-size: 18px;
  }

  .menu li a:hover {
    text-decoration: underline;
  }

  .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    border-color: #ccc transparent transparent;
  }
  .dropdown.menu .is-dropdown-submenu {
    margin-top: 10px;
  }
  .dropdown.menu a.button {
    font-size: 18px;
    margin-top: 5px;
    padding: 5px 15px;
  }

}